<template>
  <div class="">
    <TopBar title="赞助">
      <BackIcon></BackIcon>
    </TopBar>

    <v-container class="px-5">
      <TopInfo :title="title" :img="imgPath"></TopInfo>
      <div class="text-subtitle-1 grey--text">如果您觉得好用，可以赞助我们，您的支持会是我们继续维护的动力</div>

      <div class="descs" v-for="(desc, i) in descs" :key="i">
        <div class="text-subtitle-1 mt-5">{{ desc.title }}</div>
        <div class="text-subtitle-1 grey--text">{{ desc.text }}</div>
      </div>
      <v-avatar size="200" rounded>
        <v-img src="~/assets/img/alipay.jpg"></v-img>
      </v-avatar>
    </v-container>
    <!-- 根据屏幕尺寸来判断是否需要fixed以及OverFlowY -->
    <OverFlowY v-if="!isLargeScreen"></OverFlowY>
  </div>
</template>

<script>
import { isLargeScreenMixin, setChildViewMixin } from 'common/mixin'
import caffe from 'assets/img/caffe.svg'
import caffeDark from 'assets/img/caffeDark.svg'
export default {
  name: 'Sponsor',
  mixins: [isLargeScreenMixin, setChildViewMixin],
  data() {
    return {
      title: '请我们喝咖啡',
      descs: [
        {
          title: '收入来源：',
          text: '目前我们没有任何收入来源，秉持着用爱发电的态度😢'
        },
        {
          title: '支付宝：',
        }
      ]
    }
  },
  methods: {

  },
  computed: {
    imgPath() {
      return this.isDark ? caffeDark :caffe
    }
  }
}
</script>

<style scoped>

</style>